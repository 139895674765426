<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      :md="mdRegion ? mdRegion : 6"
      :lg="lgRegion ? lgRegion : 6"
    >
      <v-autocomplete
        :label="'select Region of Domicile'"
        :value="inputRegion"
        @input="$emit('input-region', $event)"
        :items="locations"
        :item-text="'name'"
        return-object
        autocomplete="region"
        name="region"
        @change="fetchLGAs($event)"
        :rules="requiredRegion ? [isValid.requiredRegion] : []"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="12" :md="mdLga ? mdLga : 6" :lg="lgLga ? lgLga : 6">
      <v-autocomplete
        :label="'select LGA of Domicile'"
        :value="inputDistrict"
        @input="$emit('input-district', $event)"
        :items="lgas"
        :item-text="'name'"
        return-object
        required
        autocomplete="lga"
        name="lga"
        :rules="requiredLga ? [isValid.requiredLga] : []"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import {
  get as fetchLocation,
  schools as fetchSchools,
  getByChildren,
  all as regions,
} from "../locations/services";

export default {
  name: "FacilityLoader",
  // props: ["selected", "setActive"],

  props: {
    inputRegion: {
      // type: String,
      required: true,
    },

    inputDistrict: {
      // type: String,
      required: true,
    },

    mdRegion: { type: Number, required: false },
    lgRegion: { type: Number, required: false },

    mdLga: { type: Number, required: false },
    lgLga: { type: Number, required: false },

    requiredRegion: { type: Boolean, required: false, default: false },
    requiredRegionMessage: { type: String, required: false },

    requiredLga: { type: Boolean, required: false, default: false },
    requiredLgaMessage: { type: String, required: false },
  },

  data() {
    return {
      items: [],
      region: null,
      lga: null,
      school: null,
      type: null,
      lgas: [],
      schools: [],
      schoolTypes: [],
      formData: {},

      isValid: {
        requiredRegion: (v) => !!v || this.requiredRegionMessage,
        requiredLga: (v) => !!v || this.requiredLgaMessage,
      },
    };
  },

  methods: {
    input(event) {
      this.$emit("input", event);
    },

    fetchLGAs(data) {
      getByChildren({
        "location.Id": data.id,
      }).then((response) => {
        this.lgas = response.data[0]
          ? response.data[0].data
              .map((lga) => ({
                ...lga,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    init(params) {
      regions({
        level: 2,
        ...params,
        size: 50,
      }).then((response) => {
        this.items = response.data[0]
          ? response.data[0].data
              .map((lga) => ({
                ...lga,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },
  },

  mounted() {
    if (this.inputRegion && this.inputRegion.id) {
      this.fetchLGAs(this.inputRegion);
    }
    this.init();
  },

  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    locations() {
      return this.items.map((item) => ({
        id: item.id,
        type: item.school_type ? item.school_type.name : "",
        name: item.name,
        level: item.level.name,
        ...item,
      }));
      // .map((item) => ({
      //   ...item,
      //   name:
      //     item.name + " " + "(" + item.type + "  " + item.level + ")" + " ",
      // }));
    },
  },

  watch: {
    //on change LGA then reset schoolTypes and  and schools
  },
};
</script>

<style scoped lang="scss"></style>
